import React, { useState } from 'react'

import axios from "axios";
import config from '../../config';

import { NavLink, useNavigate } from 'react-router-dom';
import { Button, message, Form, Input } from 'antd';



function Login() {

  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  let navigate = useNavigate();

  const handleSubmit = (formData) => {
    //console.log(formData);
    setLoading(true);

    var api_config = {
      method: 'post',
      url: config.base_url + 'auth/signin',
      headers: {
        'Content-Type': 'application/json'
      },
      data: formData
    };

    axios(api_config)
      .then(function (response) {
       // console.log(response.data)

        if (response.data.status == true) {

          localStorage.setItem("AccessKey", response.data.AccessKey);
          localStorage.setItem("User", JSON.stringify(response.data.user));
          navigate('/dashboard');
          setLoading(false);
          // 
          // Handle success
        } else {
          // Handle error
          setLoading(false);
          messageApi.open({
            type: 'error',
            content: response.data.message,
          });

        }

      })
      .catch(function (error) {

        console.log(error);
      });


  };
  return (
    <>
      {contextHolder}
      <div class="auth-wrap">
        <div class="auth-head">
          <div class="logo">
            <img src="images/logo.svg" alt="" />
          </div>
          <p>Welcome to</p>
          <h1>Daybook</h1>
        </div>
        <div class="auth-card">
          <Form
            name="normal_login"
            className="login-form"
            size="large"
            layout="vertical"
            onFinish={handleSubmit}
          >


            <Form.Item
              name="Email"
              label="Email Address"
              rules={[
                {
                  required: true,
                  message: 'Please enter email address',
                },
              ]}
            >
              <Input prefix={<span className="material-symbols-outlined site-form-item-icon">email</span>} placeholder="Username" />
            </Form.Item>
            <Form.Item
              name="Password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
              ]}
            >
              <Input
                prefix={<span className="material-symbols-outlined site-form-item-icon">lock</span>}
                type="password"
                placeholder="Password"
              />
            </Form.Item>


            <Form.Item>
              <Button style={{ width: "100%" }} type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                Log in
              </Button>

              <div className='text-center' style={{ marginTop: "30px" }}>
                <a href="">Register Now!</a>
              </div>

            </Form.Item>
          </Form>


        </div>
        <div class="shape-1">
          <img src="/images/shape-top.svg" alt="" />
        </div>
        <div class="shape-2">
          <img src="/images/shape-bottom.svg" alt="" />
        </div>
      </div>
    </>
  )
}

export default Login

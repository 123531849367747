import React, { useEffect, useState, useRef } from "react"
import axios from 'axios'
import config from '../../config'
import { Space, Table, Popconfirm, Row, Col, Breadcrumb, Button, Input, Card, Modal, Form, message } from 'antd';
import { NavLink } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons';
import AddAccount from "./AddAccount";
import { NumberFormat } from "../Shared/Functions";
import dayjs from "dayjs";

function ManageAccounts() {
  let user1 = localStorage.getItem("User");
  const [user, setUser] = useState(JSON.parse(user1));
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(user.ID);

  const [listofAccounts, SetlistofAccounts] = useState([])
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const [IsUpdated, setIsUpdated] = useState(false);
  const [dataSourceWithTotal, setdataSourceWithTotal] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const [messageApi, contextHolder] = message.useMessage();
  const currentDate = dayjs();
  const firstDayOfMonth = currentDate.startOf('month').format('YYYY-MM-DD');
  const lastDayOfMonth = currentDate.endOf('month').format('YYYY-MM-DD');


  // edit modal
  const [selectedAccount, setSelectedAccount] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedAccount({});
  };


  useEffect(() => {
    document.title = "Manage Accounts - Daybook";
  }, []);


  useEffect(() => {
    setLoading(true);
    setIsUpdated(false);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey
    }
    var api_config = {
      method: 'post',
      url: config.base_url + 'accounts/get',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

   // console.log(api_config);
    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status == true) {
          // Handle success
          SetlistofAccounts(response.data.ListOfAccounts);
          setLoading(false);

        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {

        console.log(error);
      });




  }, [IsUpdated]);



  const IsAccountAdded = () => {
    setIsUpdated(true);
  }


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? text : text
  });


  const columns = [
    {
      title: 'SR #',
      dataIndex: '',
      key: 'sr',
      render: (text, record, index) => (
        record.id == 'total' ? '' : index + 1
      ),
    },
    {
      title: 'Name',
      dataIndex: 'AccountName',
      key: 'name',
      ...getColumnSearchProps('AccountName'),
    },
    {
      title: 'Mobile',
      dataIndex: 'Mobile',
      key: 'mobile',
      render: (text, record) => (
        record.id == 'total' ? <strong style={{ display: "block", textAlign: "right" }}>{record.Mobile}</strong> : record.Mobile
      ),
    },


    {
      title: 'Balance',
      dataIndex: 'Balance',
      key: 'balance',
      render: (text, record) => (
        record.id == 'total' ? <strong>{NumberFormat(record.Balance)}</strong> : NumberFormat(record.Balance)
      ),
    },

    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        record.id == 'total' ? '' : <Space size="middle">
          <a onClick={(e) => handleUpdate(record)}>Edit</a>
          <Popconfirm title="Sure to delete?">
            <a>Delete</a>
          </Popconfirm>
          <NavLink to={`/accounts/ledger/?id=${record.ID}&str=${firstDayOfMonth}&end=${lastDayOfMonth}`}>Ledger</NavLink>
        </Space>
      ),
    },

  ]



  useEffect(() => {
    if (listofAccounts) {
      const AccountsTotal = listofAccounts.reduce((acc, current) => acc + parseFloat(current.Balance), 0);
      const dataSourceWithTotal = [...listofAccounts, { id: 'total', Mobile: 'Total', Balance: AccountsTotal }];
      setdataSourceWithTotal(dataSourceWithTotal);
    }
  }, [loading])



  const handleUpdate = (record) => {
    setIsModalOpen(true);
    setSelectedAccount(record);
  }

  const [form] = Form.useForm();

  const onFinish = (formData) => {

    setLoadingUpdate(true);
    formData['Mobile'] = formData['Mobile'] == undefined ? null : formData['Mobile'];
    formData['Email'] = formData['Email'] == undefined ? null : formData['Email'];
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ...formData
    }

   // console.log(data);
    var api_config = {
      method: 'post',
      url: config.base_url + 'accounts/do_update_account',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(api_config)
        .then(function (response) {
            //console.log(response.data);
            if (response.data.status == true) {
                // Handle success
                IsAccountAdded();
                handleCancel();

                messageApi.open({
                    type: 'success',
                    content: response.data.message,
                  });


                  setLoadingUpdate(false);


            } else {
                // Handle error
                messageApi.open({
                    type: 'error',
                    content: 'Something went wrong..',
                  });
                  setLoadingUpdate(false);
            }
            //   setLoading(false);
        })
        .catch(function (error) {

            console.log(error);
        });
  }

  return (
    <>
      {contextHolder}
      <Modal title="Update Account" footer={false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form
          name="basic"

          form={form}
          onFinish={onFinish}
          //onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          fields={
            [
              {
                name: "AccountName",
                value: selectedAccount.AccountName,
              },
              {
                name: "Mobile",
                value: selectedAccount.Mobile,
              },
              {
                name: "Email",
                value: selectedAccount.Email,
              },
              {
                name: "ID",
                value: selectedAccount.ID,
              },
            ]
          }


        >
          <Form.Item
            label="Account Name"
            name="AccountName"
            rules={[
              {
                required: true,
                message: 'Please enter account name!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="ID"
            name="ID"
            hidden={true}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Mobile"
            name="Mobile"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="Email"
          >
            <Input />
          </Form.Item>
          <Button block type="primary" htmlType="submit" loading={loadingUpdate}>Add Account</Button>
        </Form>
      </Modal>



      <div class="page-content">
        <Row>
          <Col span={12}>
            <Breadcrumb
              items={[
                {
                  title: <NavLink to="/dashboard/analytics">Dashboard</NavLink>,
                },
                {
                  title: 'Accounts',
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <div className='text-end'>
              <NavLink to="/accounts/print-account-list" target='_blank'>
                <Button
                  type='dashed'
                  style={{ marginBottom: 10 }}
                //className='d-inline-flex' 
                //icon={<i style={{lineHeight: "1"}} className="material-symbols-outlined">print</i>}
                >Print Accounts List</Button>
              </NavLink>
            </div>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} md={6}>
            <Card title="Add Account">
              <AddAccount IsAccountAdded={IsAccountAdded} />
            </Card>
          </Col>
          <Col xs={24} md={18}>
            <Table
              size="small"
              loading={loading}
              columns={columns}
              dataSource={dataSourceWithTotal}
              pagination={false}
            />
          </Col>
        </Row>





      </div>


    </>
  )
}

export default ManageAccounts

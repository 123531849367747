import axios from 'axios';
import React, { useEffect, useState } from 'react'
import config from '../../config';


function AccountDropDown() {


    let user1 = localStorage.getItem("User");
    const [user, setUser] = useState(JSON.parse(user1));
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(user.ID);
    const [listofAccounts, setlistofAccounts] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const data = {
            UserID: UserID,
            AccessKey: AccessKey
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'accounts/get',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status == true) {
                    // Handle success
                    setlistofAccounts(response.data.ListOfAccounts);
                    setLoading(false);
                } else {
                    // Handle error
                    
                }
                //   setLoading(false);
            })
            .catch(function (error) {
              
                console.log(error);
            });

    }, []);

    if (!loading) {

        // Return the array of objects in the specified format
        const result = listofAccounts.map((item) => ({
            value: item.ID,
            label: item.AccountName,
        }));

        return result;

    }
    else {
        return false;
    }



   
}

export default AccountDropDown

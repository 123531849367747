import React, { useState, useEffect } from 'react';


import { NavLink } from 'react-router-dom';
import axios from "axios";


import logo from "../../assets/images/header-img.png"

import { useNavigate } from 'react-router-dom';



import config from '../../config';
import Header from '../Layout/Header';
import DashboardMenu from './DashboardMenu';
import { Avatar, Breadcrumb, Card, Col, List, Row, Space, Spin, Statistic } from 'antd';
import { LoadingOutlined, UserOutlined, PhoneOutlined, MailOutlined, EnvironmentOutlined } from '@ant-design/icons';


function Analytics() {

  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [NoOfAccounts, SetNoOfAccounts] = useState("");
  const [TotalSales, SetTotalSales] = useState("");
  const [TotalExpenses, SetTotalExpenses] = useState("")
  const [TotalPurchases, SetTotalPurchases] = useState("")
  const Email_Address = localStorage.getItem("Email_Address")
  const Mobile_No = localStorage.getItem("Mobile_No")
  const Full_Name = localStorage.getItem("Full_Name")
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  useEffect(() => {
    setLoading(true)
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,

    }

    var api_config = {
      method: 'post',
      url: config.base_url + 'Members/GetDashboardData',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    //console.log(api_config);

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          // Handle success
          SetNoOfAccounts(response.data.NoOfAccounts);
          SetTotalSales(response.data.TotalSales);
          SetTotalExpenses(response.data.TotalExpenses)
          SetTotalPurchases(response.data.TotalPurchases)
          setLoading(false)

        } else {
          // Handle error
          setLoading(false);

        }

      })
      .catch(function (error) {
        setLoading(false)
        console.log(error);
      });




  }, []);
  const data = [
    {
      title: `You logged in as ${Full_Name}`,
      avatar: <UserOutlined />,
    },
    {
      title: 'Phone-No',
      avatar: <PhoneOutlined />,
      description: Mobile_No



    },
    {
      title: 'E-mail',
      avatar: <MailOutlined />,
      description: Email_Address,
    },
  ];

  return (
    <>

      <div className="right-side-contents">
       
       
      </div>
    </>
  )
}

export default Analytics

import React, { useEffect, useState } from "react"
import axios from 'axios'
import config from '../../config'
import { Col, Row, Spin } from 'antd';
import { NumberFormat, calculateColumnTotal } from "../Shared/Functions";
import dayjs from "dayjs";
import ReportHeader from "../Shared/ReportHeader";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

function PrintDaybook() {

    let user1 = localStorage.getItem("User");
    const [user, setUser] = useState(JSON.parse(user1));
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(user.ID);


    const [loading, setLoading] = useState(false);
    const [listofExpense, SetlistofExpense] = useState([])
    const [listofIncome, SetlistofIncome] = useState([])
    const [PreviousBalance, setPreviousBalance] = useState(0);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const dateParam = queryParams.get('date');


    useEffect(() => {
        document.title = "Print Daybook";
    }, []);





    useEffect(() => {
        setLoading(true);

        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            Date: dateParam
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'daybook/get_expense',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(api_config);
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status == true) {
                    // Handle success
                    SetlistofExpense(response.data.ListOfExpense);


                    setLoading(false);

                } else {
                    // Handle error
                    setLoading(false);
                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });




    }, []);


    useEffect(() => {
        setLoading(true);

        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            Date: dateParam
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'daybook/get_income',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(api_config);
        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status == true) {
                    // Handle success
                    SetlistofIncome(response.data.ListOfIncome);
                    setPreviousBalance(response.data.PreviousBalance);
                    setLoading(false);


                } else {
                    // Handle error
                    setLoading(false);
                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });




    }, []);



    const renderExpense = () => {
        if (listofExpense.length > 0) {
            return (
                listofExpense.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.Description}</td>
                            <td>{item.AccountName}</td>
                            <td>{NumberFormat(item.Amount)}</td>
                        </tr>
                    )
                })
            )
        }
    }
    const renderIncome = () => {
        if (listofIncome.length > 0) {
            return (
                listofIncome.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.Description}</td>
                            <td>{item.AccountName}</td>
                            <td>{NumberFormat(item.Amount)}</td>
                        </tr>
                    )
                })
            )
        }
    }








    return (
        <>
            <div className="page">
                <div className="subpage">
                    {
                        loading ?
                            <Spin />
                            :
                            <>
                                <ReportHeader />

                                <h2 style={{ fontSize: "15px" }}>Daybook {dayjs(dateParam).format("D, MMM YYYY")}</h2>

                                <Row gutter={[20, 20]}>
                                    <Col span={12}>
                                        <div className="report-section-title">
                                        Expense
                                        </div>
                                        <table className="report-table accounts-print-table">
                                            <thead style={{ backgroundColor: "#FFF" }}>
                                                <tr>
                                                    <th>SR#</th>
                                                    <th>Description</th>
                                                    <th>Account</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderExpense()}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td style={{ textAlign: "right" }}><strong>Total:</strong></td>
                                                    <td><strong>{NumberFormat(calculateColumnTotal(listofExpense, 'Amount'))}</strong></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </Col>
                                    <Col span={12}>
                                        <div className="report-section-title">
                                        Income
                                        </div>
                                        <table className="report-table accounts-print-table">
                                            <thead style={{ backgroundColor: "#FFF" }}>
                                                <tr>
                                                    <th>SR#</th>
                                                    <th>Description</th>
                                                    <th>Account</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderIncome()}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    
                                                    <td colSpan={3} style={{ textAlign: "right" }}><strong>Income:</strong></td>
                                                    <td><strong>{NumberFormat(calculateColumnTotal(listofIncome, 'Amount'))}</strong></td>
                                                </tr>
                                                <tr>
                                                  
                                                    <td colSpan={3} style={{ textAlign: "right" }}><strong>Previous Balance:</strong></td>
                                                    <td><strong>{NumberFormat(PreviousBalance)}</strong></td>
                                                </tr>
                                                <tr>
                                                   
                                                    <td colSpan={3} style={{ textAlign: "right" }}><strong>Total:</strong></td>
                                                    <td><strong>{NumberFormat(calculateColumnTotal(listofIncome, 'Amount') + parseInt(PreviousBalance) )}</strong></td>
                                                </tr>
                                                <tr>
                                                  
                                                    <td colSpan={3} style={{ textAlign: "right" }}><strong>Expense:</strong></td>
                                                    <td><strong>{NumberFormat(calculateColumnTotal(listofExpense, 'Amount'))}</strong></td>
                                                </tr>
                                                <tr>
                                                  
                                                    <td colSpan={3} style={{ textAlign: "right" }}><strong>Balance:</strong></td>
                                                    <td><strong>{NumberFormat(calculateColumnTotal(listofIncome, 'Amount') + parseInt(PreviousBalance) - calculateColumnTotal(listofExpense, 'Amount') )}</strong></td>
                                                </tr>
                                                
                                            </tfoot>
                                        </table>

                                    </Col>
                                </Row>
                            </>
                    }


                </div>
            </div>
        </>
    )
}

export default PrintDaybook

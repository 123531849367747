import React, { useState, useEffect } from "react";
import { Col, Row, Calendar, Input, Form, Button, Select, Card, Table, DatePicker, Spin, Popconfirm, Tooltip } from "antd";

import axios from "axios";
import config from "../../config";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { NumberFormat } from "../Shared/Functions";
import AccountDropDown from "../Shared/AccountDropDown";
import { DeleteTwoTone } from "@ant-design/icons";
import TagsDropdown from "../Shared/TagsDropdown";

function Income(props) {

    let user1 = localStorage.getItem("User");
    const [user, setUser] = useState(JSON.parse(user1));
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(user.ID);

    const [listofIncome, SetlistofIncome] = useState([])
    const [loading, setLoading] = useState(false);
    const [SaveLoading, setSaveLoading] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const dateParam = queryParams.get('date');

    const [Balance, setBalance] = useState(0);
    const [dataSourceWithTotal, setdataSourceWithTotal] = useState([]);
    const [AccountID, setAccountID] = useState(0);
    const [TransactionDate, setTransactionDate] = useState("");
    const [IsEntrySaved, setIsEntrySaved] = useState(false);
    const options = TagsDropdown();
    const accounts = AccountDropDown();
    const [selectedAccount, setSelectedAccount] = useState(0);
    const [form] = Form.useForm();

    useEffect(() => {
        setLoading(true);
        setIsEntrySaved(false);
        //setTransactionDate(dateParam);
        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            Date: dateParam
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'daybook/get_income',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(api_config);
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status == true) {
                    // Handle success

                    var list = response.data.ListOfIncome;
                    if(list.length > 0)
                    {
                        let lastItem = list[list.length - 1];
                        var lastDate = lastItem.TransactionDate;
                        setTransactionDate(lastDate);
                    }

                    SetlistofIncome(response.data.ListOfIncome);
                    setSelectedAccount(response.data.LastAccountId);
                    setAccountID(response.data.LastAccountId)
                    props.incSaved();
                    setLoading(false);
                    

                } else {
                    // Handle error
                    setLoading(false);
                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });




    }, [dateParam, IsEntrySaved, selectedAccount]);



    const rowsWithoutDates = ['totalIncome', 'expense', 'previousBalance', 'total', 'balance'];

    const columns = [
        {
            title: 'Date',
            dataIndex: 'TransactionDate',
            key: 'date',
            render: (_, record) => (
                rowsWithoutDates.includes(record.id) ? '' : dayjs(record.TransactionDate).format("DD-MM-YYYY")
            ),
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'description',
            // render: (_, record) => (
            //     record.Tags != null ? <Tooltip title={record.Tags}>{record.Description}</Tooltip> : record.Description
            // )
        },
        {
            title: 'Tags',
            dataIndex: 'Tags',
            key: 'Tags',
            // render: (_, record) => (
            //     record.Tags != null ? <Tooltip title={record.Tags}>{record.Description}</Tooltip> : record.Description
            // )
        },
        {
            title: 'Account',
            dataIndex: 'AccountName',
            key: 'account',
            render: (_, record) => (
                rowsWithoutDates.includes(record.id) ? <strong style={{ display: "block", textAlign: "right" }}>{record.AccountName}</strong> : record.AccountName
            ),
        },
        {
            title: 'Amount',
            dataIndex: 'Amount',
            key: 'amount',
            render: (_, record) => (
                rowsWithoutDates.includes(record.id) ? <strong>{NumberFormat(record.Amount)}</strong> : NumberFormat(record.Amount)
            ),
        },
        {
            title: '',
            dataIndex: '',
            key: 'action',
            render: (_, record) => (
                props.pagestatus ?
                    rowsWithoutDates.includes(record.id) ? "" :
                        <Popconfirm
                            title="Delete the Income"
                            description="Are you sure to delete this entry?"
                            onConfirm={(e) => deleteRecord(record.ID)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteTwoTone />
                        </Popconfirm>
                    :
                    ""
            )
        }
    ];

    const deleteRecord = (ID) => {
        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            ID: ID,
        }

        

        var api_config = {
            method: 'post',
            url: config.base_url + 'daybook/delete_income',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(api_config);
        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status == true) {
                    setIsEntrySaved(true);
                    // Handle success
                    //SetlistofIncome(response.data.ListOfIncome);
                    //setLoading(false);

                } else {
                    // Handle error
                    //setLoading(false);
                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });

    }


    useEffect(() => {

        if (props.loading === false) {
            if (listofIncome) {
                const totalIncome = listofIncome.reduce((acc, current) => acc + parseFloat(current.Amount), 0);
                //const dataSourceWithTotal = [...listofIncome, { id: 'total', Dated: null, AccountName: 'Total', Amount: totalIncome }]

                const total = parseInt(props.PreviousBalance) + parseInt(totalIncome);
                const balance = total - props.expense;

                const TotalBalance = parseInt(props.PreviousBalance) + parseInt(totalIncome) - parseInt(props.expense);
                setBalance(TotalBalance);

                const InputBalance = document.getElementById('InputBalance');
                const InputIncome = document.getElementById('InputIncome');
                const InputExpense = document.getElementById('InputExpense');

                if (InputIncome) {
                    InputIncome.value = totalIncome;
                }

                if (InputBalance) {
                    InputBalance.value = TotalBalance;
                }

                if (InputExpense) {
                    InputExpense.value = props.expense;
                }

                const dataSourceWithTotal = [
                    ...listofIncome,
                    { id: 'totalIncome', Dated: null, AccountName: 'Total Income', Amount: totalIncome },
                    { id: 'previousBalance', Dated: null, AccountName: 'Previous Balance', Amount: props.PreviousBalance },
                    { id: 'total', Dated: null, AccountName: 'Total', Amount: parseInt(props.PreviousBalance) + parseInt(totalIncome) },
                    { id: 'expense', Dated: null, AccountName: 'Expense', Amount: props.expense },
                    { id: 'balance', Dated: null, AccountName: 'Balance', Amount: balance },
                ];

                setdataSourceWithTotal(dataSourceWithTotal);
                //setBalance(balance);
            }
        }
    }, [dateParam, loading, IsEntrySaved, props.loading, props.isexpsaved])


    const onFinish = (formData) => {
        setSaveLoading(true);
        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            Type: 'income',
            Account_ID: AccountID,
            Dated: dateParam,
            TransactionDate: TransactionDate,
            Balance: Balance,
            ...formData
        }

       // console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'daybook/save_daybook',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(api_config);
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status == true) {
                    setIsEntrySaved(true);
                    setSaveLoading(false);
                    form.resetFields(['Description', 'Amount', 'Tags']);
                    // Handle success
                    //SetlistofIncome(response.data.ListOfIncome);
                    //setLoading(false);

                } else {
                    setSaveLoading(false);
                    // Handle error
                    //setLoading(false);
                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });

    }


    const onSelectAccount = (item) => {
        //console.log(item);
        setAccountID(item);
    }

    const onDateChange = (date, dateString) => {
        setTransactionDate(dateString);
    };

    return (
        <>
            <input type="hidden" id="InputExpense" />
            <input type="hidden" id="InputIncome" />
            <input type="hidden" id="InputBalance" />
            <Card title="Income" size="small">
                <Table dataSource={dataSourceWithTotal} columns={columns} size="small" pagination={false} loading={loading} />

                {
                    props.loading ?
                        <Spin />
                        :

                        props.pagestatus ?

                            <Form
                                name="basic"
                                layout="vertical"
                                form={form}
                                onFinish={onFinish}
                                //onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Row gutter={[16, 0]} style={{ marginTop: "30px" }}>
                                    <Col xs={24} md={6}>
                                        <Form.Item
                                            label="Date"
                                            name="Date1"
                                            initialValue={dayjs(TransactionDate, "YYYY-MM-DD")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select date',
                                                },
                                            ]}
                                        >
                                            <DatePicker onChange={onDateChange} style={{ width: "100%" }} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={6}>
                                    <Form.Item
                                            label="Description"
                                            name="Description"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter description',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>

                                        
                                    </Col>
                                    <Col xs={24} md={6}>
                                        <Form.Item
                                            label="Account"
                                            name="AccountID"
                                            initialValue={selectedAccount}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select account',
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select Account"
                                                optionFilterProp="children"
                                                onChange={onSelectAccount}
                                                // onSearch={onSearch}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                loading={accounts === false ? true : false}
                                                options={accounts}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={6}>
                                        <Form.Item
                                            label="Amount"
                                            name="Amount"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter Amount',
                                                },
                                                {
                                                    pattern: /^[0-9]+$/,
                                                    message: 'Please enter only numeric values',
                                                },
                                            ]}
                                        >
                                            <Input type="text" />
                                        </Form.Item>
                                    </Col>

                                   
                                   
                                    <Col xs={24} md={18}>
                                    <Form.Item
                                            label="Tags"
                                            name="Tags"
                                        >

                                            <Select
                                                showSearch
                                                mode="tags"
                                                placeholder="Select Tags"
                                                optionFilterProp="children"
                                                //onChange={onSelectTags}
                                                // onSearch={onSearch}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={options}
                                            // loading={accounts === false ? true : false}
                                            // options={accounts}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={6}>
                                        <Button
                                            type="primary"
                                            block
                                            htmlType="submit"
                                            loading={SaveLoading}
                                            style={{ marginTop: "30px" }}>
                                            Save
                                        </Button>
                                    </Col>
                                </Row>







                            </Form>
                            :
                            ""
                }
            </Card>
        </>
    );
}

export default Income;
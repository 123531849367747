
import React, { useState, useEffect } from "react";

import { Col, Row, message } from "antd";


import Transfers from "./Transfers";
import DaybookHeader from "./DaybookHeader";
import Expense from "./Expense";
import Income from "./Income";
import config from "../../config";
import axios from "axios";
import { useLocation } from "react-router-dom";


function Daybook(props) {



    let user1 = localStorage.getItem("User");
    const [user, setUser] = useState(JSON.parse(user1));
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(user.ID);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const dateParam = queryParams.get('date');
    const [IsPageOpen, setIsPageOpen] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [ExpenseSaved, setExpenseSaved] = useState(false);
    const [IncomeSaved, setIncomeSaved] = useState(false);
    const [PreviousBalance, setPreviousBalance] = useState(0);
    const [TotalIncome, setTotalIncome] = useState(0);
    const [TotalExpense, setTotalExpense] = useState(0);
    const [UpdatePages, setUpdatePages] = useState(false);

    useEffect(() => {
        setLoading(true);
        setExpenseSaved(false);
        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            Date: dateParam
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'daybook/get_page',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(api_config);
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status == true) {
                    if (response.data.page_status == "open") {
                        setIsPageOpen(true);
                    }
                    else {
                        setIsPageOpen(false);
                    }

                    if (response.data.page_message != "") {
                        messageApi.open({
                            type: 'info',
                            content: response.data.page_message,
                        });
                    }

                    // Handle success
                    setPreviousBalance(response.data.previous_balance);
                    setTotalIncome(response.data.total_inc);
                    setTotalExpense(response.data.total_exp);
                    setLoading(false);

                } else {
                    // Handle error
                    setLoading(false);
                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });




    }, [dateParam, ExpenseSaved]);

    const expsaved = () => {
        setExpenseSaved(true);
        setUpdatePages(true);
        
    }

    const incSaved = () => {
        setUpdatePages(true);
        setIncomeSaved(true);
    }

    return (
        <>
            {contextHolder}
            <div className="daybook-content">
                <DaybookHeader updatepages={UpdatePages} pagestatus={IsPageOpen} loading={loading} />
                <Row gutter={[24, 24]}>
                    <Col xs={24} md={12}>
                        <Expense 
                            expsaved={expsaved} 
                            pagestatus={IsPageOpen} 
                            loading={loading} />
                    </Col>
                    <Col xs={24} md={12}>
                        <Income
                            incSaved={incSaved}
                            pagestatus={IsPageOpen}
                            PreviousBalance={PreviousBalance}
                            expense={TotalExpense === null ? 0 : TotalExpense}
                            isexpsaved={ExpenseSaved}
                            loading={loading} />
                    </Col>
                </Row>
                <Transfers pagestatus={IsPageOpen} loading={loading} />
            </div>
        </>
    );
}

export default Daybook;
import React, { useEffect, useState } from 'react'
import LeftMenu from './LeftMenu'
import { Outlet } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import Header from './Header';


function Main() {

  // const AccessKey = localStorage.getItem("AccessKey");


  // let navigate = useNavigate();

  // useEffect(() => {
  //   //console.log(AccessKey);
  //   if (AccessKey == null) {
  //     navigate("/login");
  //   }

  // }, []);

  return (
    <>
      <div class="wrapper">


        <Header />
        <Outlet />

      </div>
    </>
  )
}

export default Main

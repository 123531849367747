import React, { useState, useEffect } from "react";
import { Col, Row, Tooltip, Input, Form, Button, Space, Card, Table, DatePicker, Spin, Select, Popconfirm } from "antd";

import axios from "axios";
import config from "../../config";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { NumberFormat } from "../Shared/Functions";
import AccountDropDown from "../Shared/AccountDropDown";
import { DeleteFilled, DeleteTwoTone } from "@ant-design/icons";
import TagsDropdown from "../Shared/TagsDropdown";

function Expense(props) {


    let user1 = localStorage.getItem("User");
    const [user, setUser] = useState(JSON.parse(user1));
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(user.ID);

    const [listofExpense, SetlistofExpense] = useState([])
    const [loading, setLoading] = useState(false);
    const [dataSourceWithTotal, setdataSourceWithTotal] = useState([]);
    const [FromAccountID, setFromAccountID] = useState(0);
    const [ToAccountID, setToAccountID] = useState(0);
    const [SaveLoading, setSaveLoading] = useState(false);
    const [AccountID, setAccountID] = useState(0);
    const [TransactionDate, setTransactionDate] = useState("");
    const [IsExpenseSaved, setIsExpenseSaved] = useState(false);
    const [Balance, setBalance] = useState(0);
    const options = TagsDropdown();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const dateParam = queryParams.get('date');
    const [form] = Form.useForm();
    const accounts = AccountDropDown();
    const [selectedAccount, setSelectedAccount] = useState(0);
    

    useEffect(() => {
        setLoading(true);
        setIsExpenseSaved(false);
        //setTransactionDate(dateParam);
        const InputBalance = document.getElementById('InputBalance');
        const totalBalance = InputBalance ? InputBalance.value : 0;
        setBalance(totalBalance);

        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            Date: dateParam
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'daybook/get_expense',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(api_config);
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status == true) {
                    // Handle success
                    SetlistofExpense(response.data.ListOfExpense);

                    var list = response.data.ListOfExpense;
                    if(list.length > 0)
                    {
                        let lastItem = list[list.length - 1];
                        var lastDate = lastItem.TransactionDate;
                        setTransactionDate(lastDate);
                    }

                    // let data = response.data.ListOfExpense;

                    // let lastid = data.length > 0 ? data[data.length - 1].Account_ID : 0;
                    // console.log(lastid);
                    setSelectedAccount(response.data.LastAccountId);

                    setLoading(false);

                } else {
                    // Handle error
                    setLoading(false);
                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });




    }, [dateParam, IsExpenseSaved, selectedAccount]);



    

    const columns = [
        {
            title: 'Date',
            dataIndex: 'TransactionDate',
            key: 'date',
            render: (_, record) => (
                record.id == "total" ? "" : dayjs(record.TransactionDate).format("DD-MM-YYYY")
            )
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'description',
            render: (_, record) => (
                record.Tags != null ? <Tooltip title={record.Tags}>{record.Description}</Tooltip> : record.Description
            )
        },
        {
            title: 'Tags',
            dataIndex: 'Tags',
            key: 'Tags',
            // render: (_, record) => (
            //     record.Tags != null ? <Tooltip title={record.Tags}>{record.Description}</Tooltip> : record.Description
            // )
        },
        {
            title: 'Account',
            dataIndex: 'AccountName',
            key: 'account',
            render: (_, record) => (
                record.id == "total" ? <strong>{record.AccountName}</strong> : record.AccountName 
            )
        },
        {
            title: 'Ammount',
            dataIndex: 'Amount',
            key: 'amount',
            render: (_, record) => (
                record.id == "total" ? <strong>{NumberFormat(record.Amount)}</strong> : NumberFormat(record.Amount)
            )
        },
        {
            title: '',
            dataIndex: '',
            key: 'action',
            render: (_, record) => (
                props.pagestatus ?
                record.id == "total" ?  "" : <Popconfirm
                title="Delete the Income"
                description="Are you sure to delete this entry?"
                onConfirm={(e) => deleteRecord(record.ID)}
                okText="Yes"
                cancelText="No"
            >
                <DeleteTwoTone />
            </Popconfirm>
                :
                ""
            )
        }
    ];


    const deleteRecord = (ID) => {
        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            ID: ID,
        }

        

        var api_config = {
            method: 'post',
            url: config.base_url + 'daybook/delete_expense',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(api_config);
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status == true) {
                    setIsExpenseSaved(true);
                    props.expsaved();
                    // Handle success
                    //SetlistofIncome(response.data.ListOfIncome);
                    //setLoading(false);

                } else {
                    // Handle error
                    //setLoading(false);
                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });

    }

    useEffect(() => {
        if(listofExpense) {
            const totalExpense = listofExpense.reduce((acc, current) => acc + parseFloat(current.Amount), 0);
            const dataSourceWithTotal = [...listofExpense, { id: 'total', Dated: null, AccountName: 'Total Expense', Amount: totalExpense }];
            setdataSourceWithTotal(dataSourceWithTotal);
        }
    }, [dateParam, loading, props.loading])
    

    const onSelectFromAccount = (item) => {
        setAccountID(item);
    }

    const onSelectToAccount = (item) => {
        setToAccountID(item);
    }

    const onFinish = (formData) => {
        setSaveLoading(true);
        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            Type: 'expense',
            //Account_ID: AccountID,
            Dated: dateParam,
            TransactionDate: TransactionDate,
            Balance: Balance,
            ...formData
        }

       //console.log(data);

        var api_config = {
            method: 'post',
            url: config.base_url + 'daybook/save_daybook',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(api_config);
        axios(api_config)
            .then(function (response) {
               // console.log(response.data);
                if (response.data.status == true) {
                    setIsExpenseSaved(true);
                    setSaveLoading(false);
                    props.expsaved();
                    //form.resetFields();

                    form.resetFields(['Description', 'Amount', 'Tags']);

                } else {
                    setSaveLoading(false);
                    // Handle error
                    //setLoading(false);
                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });
        
    }


  

    const onDateChange = (date, dateString) => {
        setTransactionDate(dateString);
      };

    


    return (
        <>
            <Card title="Expense" size="small">
                <Table dataSource={dataSourceWithTotal} columns={columns} size="small" pagination={false} loading={loading} />
                {
                    props.loading ?
                        <Spin />
                        :

                        props.pagestatus ?
                            <Form
                                name="basic"
                                layout="vertical"
                                form={form}
                                onFinish={onFinish}
                                //onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Row gutter={[16, 0]} style={{ marginTop: "30px" }}>
                                    <Col xs={24} md={6}>
                                        <Form.Item
                                            label="Date"
                                            name="Date1"
                                            initialValue={dayjs(dateParam, "YYYY-MM-DD")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select date',
                                                },
                                            ]}
                                        >
                                            <DatePicker onChange={onDateChange} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={6}>
                                    <Form.Item
                                            label="Description"
                                            name="Description"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter description',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        
                                    </Col>
                                    <Col xs={24} md={6}>
                                        <Form.Item
                                            label="Account"
                                            name="Account_ID"
                                            initialValue={selectedAccount}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter description',
                                                },
                                            ]}
                                        >
                                            <Select
                                            showSearch
                                            placeholder="Select Account"
                                            optionFilterProp="children"
                                            onChange={onSelectFromAccount}
                                            // onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            loading={accounts === false ? true : false}
                                            options={accounts}
                                        />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={6}>
                                        <Form.Item
                                            label="Amount"
                                            name="Amount"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter Amount',
                                                },
                                                {
                                                    pattern: /^[0-9]+$/,
                                                    message: 'Please enter only numeric values',
                                                },
                                            ]}
                                        >
                                            <Input type="text" />
                                        </Form.Item>
                                    </Col>
                                   
                                    
                                    <Col xs={24} md={18}>
                                    <Form.Item
                                            label="Tags"
                                            name="Tags"
                                        >

                                            <Select
                                            showSearch
                                            mode="tags"
                                            placeholder="Select Tags"
                                            optionFilterProp="children"
                                            onChange={onSelectToAccount}
                                            // onSearch={onSearch}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={options}
                                            // loading={accounts === false ? true : false}
                                            // options={accounts}
                                        />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={6}>
                                        <Button 
                                            type="primary" 
                                            block 
                                            htmlType="submit" 
                                            style={{ marginTop: "30px" }}
                                            loading={SaveLoading}
                                            >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>







                            </Form>
                            :
                            ""


                }

            </Card>
        </>
    );
}

export default Expense;
import React, { useEffect, useState } from "react"
import axios from 'axios'
import config from '../../config'
import { Spin } from 'antd';
import { NumberFormat, calculateColumnTotal } from "../Shared/Functions";
import dayjs from "dayjs";



function ReportHeader(props) {

    let user1 = localStorage.getItem("User");
    const [user, setUser] = useState(JSON.parse(user1));
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(user.ID);

    const [loading, setLoading] = useState(false);



    const [company, setCompany] = useState({});

    useEffect(() => {

        setLoading(true);
        const data = {
            UserID: UserID,
            AccessKey: AccessKey
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'accounts/get_company',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(api_config);
        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status == true) {
                    // Handle success
                    setCompany(response.data.company);
                    setLoading(false);

                } else {
                    // Handle error
                    setLoading(false);
                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });




    }, []);


    return (
        <>
            {
                loading ?
                    <Spin />
                    :

                    <div className="report-header">
                        <div>
                            <h2 style={{ fontSize: "18px" }}>
                                {company.Name}
                            </h2>
                        </div>
                        <div className="text-right">
                            {company.Mobile} <br />
                            {company.Website}<br />
                            {company.Email}
                        </div>
                    </div>
            }
        </>
    );
}

export default ReportHeader;
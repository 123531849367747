import React, { useState, useEffect } from "react";
import { Col, Row, DatePicker, Button, Space, Popconfirm } from 'antd';
import { CheckCircleFilled, CloseCircleFilled, PrinterFilled } from "@ant-design/icons";
import axios from "axios";
import config from "../../config";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";


function DaybookHeader(props) {

  let user1 = localStorage.getItem("User");
  const [user, setUser] = useState(JSON.parse(user1));
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(user.ID);

  const [listofPages, SetlistofPages] = useState([])
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dateParam = queryParams.get('date');

  const [PageDate, setPageDate] = useState(dateParam);


  // page close
  const [IsPageClosed, setIsPageClosed] = useState(false);
  const [loadingBtnPageClose, setLoadingBtnPageClose] = useState(false);

  let navigate = useNavigate();

  //console.log(dateParam);

  //get_monthly_pages

  useEffect(() => {
    setLoading(true);

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Date: PageDate
    }
    var api_config = {
      method: 'post',
      url: config.base_url + 'daybook/get_monthly_pages',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };


    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status == true) {
          // Handle success
          SetlistofPages(response.data.ListOfPages);
          setLoading(false);

        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {

        console.log(error);
      });




  }, [dateParam, props.updatepages]);


  const renderPages = () => {
    if (listofPages != null) {
      return (
        listofPages.map((item, index) => {
          return (
            <li key={index}>
              <NavLink
                className={item.Dated == dateParam ? 'current' : ''}
                to={`/daybook?date=${dayjs(item.Dated).format("YYYY-MM-DD")}`}>{dayjs(item.Dated).format("DD")}</NavLink>
            </li>
          )
        })
      )
    }
  }


  const handleDateChange = (date, dateString) => {
    setPageDate(dateString);
    navigate('/daybook?date=' + dateString);
  }


  const ClosePage = () => {
    const InputBalance = document.getElementById('InputBalance');
    const InputIncome = document.getElementById('InputIncome');
    const InputExpense = document.getElementById('InputExpense');



    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Date: dateParam,
      Income: InputIncome.value,
      Expense: InputExpense.value,
      Balance: InputBalance.value,
    }

    var api_config = {
      method: 'post',
      url: config.base_url + 'daybook/close_page',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };


    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status == true) {
          // Handle success
          window.location.reload();

        } else {
          // Handle error

        }
        //   setLoading(false);
      })
      .catch(function (error) {

        console.log(error);
      });



  }

  return (
    <>
      <div className="daybook-header">

        <Row align="middle" justify="space-between">
          <Col>
            <div className="daybook-dates-wrap">
              <h4>{dayjs(dateParam).format("dddd DD MMMM, YYYY")}</h4>
              <ul className="page-list">
                {renderPages()}
              </ul>
            </div>
          </Col>
          <Col>
            <Space>
              <DatePicker onChange={handleDateChange} style={{ width: "200px" }} />
              <NavLink target="_blank" to={`/daybook/print/?date=${dateParam}`}>
              <Button icon={<PrinterFilled />}>Print Page</Button>
              </NavLink>
              {
                props.loading ?
                  <Button icon={<CloseCircleFilled />} loading={props.loading}>Close Page</Button>
                  :

                  props.pagestatus ?
                    <Popconfirm
                      title="Warning: Are you sure you want to proceed?"
                      description="Closing this page will prevent you from adding any new entries."
                      onConfirm={ClosePage}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button icon={<CloseCircleFilled />} danger={true}>Close Page</Button>
                    </Popconfirm>
                    :
                    <Button icon={<CheckCircleFilled />} disabled>Page Closed</Button>


              }


            </Space>
          </Col>
        </Row>

      </div>



      <br />
    </>
  );
}

export default DaybookHeader;

import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import config from "../../config";
import axios from "axios";


function AddAccount({ IsAccountAdded }) {


    let user1 = localStorage.getItem("User");
    const [user, setUser] = useState(JSON.parse(user1));
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(user.ID);

    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (formData) => {

        formData['Mobile'] = formData['Mobile'] == undefined ? null : formData['Mobile'];
        formData['Email'] = formData['Email'] == undefined ? null : formData['Email'];
        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            ...formData
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'accounts/add_new',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status == true) {
                    // Handle success
                    IsAccountAdded();
                    form.resetFields();

                    messageApi.open({
                        type: 'success',
                        content: response.data.message,
                      });


                    setLoading(false);


                } else {
                    // Handle error
                    messageApi.open({
                        type: 'error',
                        content: 'Something went wrong..',
                      });
                    setLoading(false);
                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });
    }

    return (
        <>
        {contextHolder}
            <Form
                name="basic"

                form={form}
                onFinish={onFinish}
                //onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"

            >
                <Form.Item
                    label="Account Name"
                    name="AccountName"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter account name!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Mobile"
                    name="Mobile"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="Email"
                >
                    <Input />
                </Form.Item>
                <Button block type="primary" htmlType="submit" loading={loading}>Add Account</Button>
            </Form>
        </>
    );
}

export default AddAccount;
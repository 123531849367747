import React from 'react';
import ReactDOM from 'react-dom/client';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

//import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/css/style.css';
import Main from './components/Layout/Main';
import Dashboard from './components/Dashboard';
import ManageAccounts from './components/Accounts/ManageAccounts';


import ChangePassword from './components/Security/ChangePassword';


import Login from './components/Auth/Login';

import SignUp from './components/Auth/SignUp';

import Analytics from './components/Dashboard/Analytics';
import Daybook from './components/Daybook/Daybook';
import ChangeCompanyInfo from './components/Security/ChangeCompanyInfo';
import AccountLedger from './components/Accounts/AccountLedger';
import PrintAccounts from './components/Accounts/PrintAccounts';
import PrintDaybook from './components/Daybook/PrintDaybook';








function Index() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />

        {/* print and reports */}
        <Route path="/accounts/print-account-list" element={<PrintAccounts />} />
        <Route path="/daybook/print" element={<PrintDaybook />} />
        
        


        <Route path="/" element={<Main />}>

          <Route path="/dashboard" element={<Analytics />} />
         
          <Route path="/accounts" element={<ManageAccounts />} />
          <Route path="/accounts/ledger" element={<AccountLedger />} />
        
          <Route path="/daybook" element={<Daybook />} />
          <Route path="/profile" element={<ChangeCompanyInfo />} />
                
          

        </Route>
      </Routes>
    </BrowserRouter>
  )
}

//<Route path="*" element={authenticate ? <DashboardHome /> : <Login />} />

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Index />);

import React, { useState, useEffect } from "react";
import { Col, Row, Select, Input, Form, Button, Popconfirm, Card, Table, DatePicker, Spin } from "antd";

import axios from "axios";
import config from "../../config";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { NumberFormat } from "../Shared/Functions";
import AccountDropDown from "../Shared/AccountDropDown";
import { DeleteTwoTone } from "@ant-design/icons";

function Transfers(props) {


    let user1 = localStorage.getItem("User");
    const [user, setUser] = useState(JSON.parse(user1));
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(user.ID);

    const [listofTransfers, SetListofTransfers] = useState([])
    const [loading, setLoading] = useState(false);
    const [SaveLoading, setSaveLoading] = useState(false);
    const [IsEntrySaved, setIsEntrySaved] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const dateParam = queryParams.get('date');

    const [TransactionDate, setTransactionDate] = useState("");
    const [form] = Form.useForm();
    const accounts = AccountDropDown();

    useEffect(() => {
        setLoading(true);
        setIsEntrySaved(false);

        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            Date: dateParam
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'daybook/get_transfers',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        // console.log(api_config);
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status == true) {
                    // Handle success
                    SetListofTransfers(response.data.ListOfTransfers);
                    setLoading(false);

                } else {
                    // Handle error
                    setLoading(false);
                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });




    }, [dateParam, IsEntrySaved]);



    const columns = [
        {
            title: 'Date',
            dataIndex: 'TransactionDate',
            key: 'date',
            render: (_, record) => (
                dayjs(record.TransactionDate).format("DD-MM-YYYY")
            )
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'description',
        },
        {
            title: 'From Account',
            dataIndex: 'FromAccount',
            key: 'fromaccount',
        },
        {
            title: 'To Account',
            dataIndex: 'ToAccount',
            key: 'toaccount',
        },
        {
            title: 'Ammount',
            dataIndex: 'Amount',
            key: 'amount',
            render: (_, record) => (
                NumberFormat(record.Amount)
            )
        },

        {
            title: '',
            dataIndex: '',
            key: 'action',
            render: (_, record) => (
                <Popconfirm
                    title="Delete the transfer"
                    description="Are you sure to delete this transfer?"
                    onConfirm={(e) => deleteTransfer(record.ID)}
                    okText="Yes"
                    cancelText="No"
                >
                    <DeleteTwoTone />
                </Popconfirm>

            )
        }
    ];


    const deleteTransfer = (ID) => {
        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            ID: ID
        }

        var api_config = {
            method: 'post',
            url: config.base_url + 'transfer/delete_transfer',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(api_config);
        axios(api_config)
            .then(function (response) {
               // console.log(response.data);
                if (response.data.status == true) {
                    setIsEntrySaved(true);
                    // Handle success
                    //SetlistofIncome(response.data.ListOfIncome);
                    //setLoading(false);

                } else {
                    // Handle error
                    //setLoading(false);
                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });

    }

    const handleTransactionDate = (date, dateString) => {
        setTransactionDate(dateString);
    }


    const onFinish = (formData) => {
        setSaveLoading(true);

        formData['Description'] = formData['Description'] == undefined ? null : formData['Description'];

        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            Dated: dateParam,
            TransactionDate: TransactionDate,
            ...formData
        }


        var api_config = {
            method: 'post',
            url: config.base_url + 'transfer/transfer_balance',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(api_config);
        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status == true) {
                    setIsEntrySaved(true);
                    setSaveLoading(false);
                    form.resetFields();
                    // Handle success
                    //SetlistofIncome(response.data.ListOfIncome);
                    //setLoading(false);

                } else {
                    setSaveLoading(false);
                    // Handle error
                    //setLoading(false);
                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });

    }


    return (
        <>
            <Card title="Transfer Details" size="small" style={{ marginTop: "30px" }}>
                <Row gutter={[16, 16]}>


                    {
                        props.loading ?
                            <Spin />
                            :

                            props.pagestatus ?
                                <Col xs={24} md={6}>
                                    <Form
                                        name="basic"

                                        form={form}
                                        onFinish={onFinish}
                                        //onFinishFailed={onFinishFailed}
                                        autoComplete="off"
                                        labelCol={{
                                            span: 8,
                                        }}
                                        wrapperCol={{
                                            span: 16,
                                        }}

                                    >
                                        <Form.Item
                                            label="Date"
                                            name=""
                                            initialValue={dayjs(dateParam, "YYYY-MM-DD")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select date',
                                                },
                                            ]}
                                        >
                                            <DatePicker onChange={handleTransactionDate} style={{ width: "100%" }} />
                                        </Form.Item>
                                        <Form.Item
                                            label="Description"
                                            name="Description"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="From Account"
                                            name="FromAccountID"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select from account',
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select Account"
                                                optionFilterProp="children"
                                                //onChange={onSelectAccount}
                                                // onSearch={onSearch}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                loading={accounts === false ? true : false}
                                                options={accounts}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="To Account"
                                            name="ToAccountID"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select to account',
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="Select Account"
                                                optionFilterProp="children"
                                                //onChange={onSelectAccount}
                                                // onSearch={onSearch}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                loading={accounts === false ? true : false}
                                                options={accounts}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Amount"
                                            name="Amount"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter amount',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            wrapperCol={{
                                                offset: 8,
                                                span: 16,
                                            }}
                                        >
                                            <Button type="primary" htmlType="submit" loading={SaveLoading}>
                                                Transfer
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Col>
                                :
                                ""
                    }

                    <Col xs={24} md={props.pagestatus ? 18 : 24}>
                        <Table dataSource={listofTransfers} columns={columns} size="small" pagination={false} scroll={{ y: 340, }} />
                    </Col>
                </Row>
            </Card>
        </>
    );
}

export default Transfers;
import React, { useEffect, useState, useRef } from "react"
import axios from 'axios'
import config from '../../config'
import { Space, Table, DatePicker, Row, Col, Breadcrumb, Button, Input, Card } from 'antd';
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons';
import AddAccount from "./AddAccount";
import { NumberFormat } from "../Shared/Functions";
import dayjs from "dayjs";

function AccountLedger(props) {

    let user1 = localStorage.getItem("User");
    const [user, setUser] = useState(JSON.parse(user1));
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(user.ID);

    const [ledger, SetLedger] = useState([])
    const [loading, setLoading] = useState(false);
    const [Account, setAccount] = useState({});
    const [OpeningBalance, setOpeningBalance] = useState(0);
    const [dataSourceWithTotal, setdataSourceWithTotal] = useState([]);

    let navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const ID = queryParams.get('id');
    const str = queryParams.get('str');
    const end = queryParams.get('end');



    const { RangePicker } = DatePicker;

    useEffect(() => {
        document.title = "Account Ledger - Daybook";
    }, []);


    useEffect(() => {
        setLoading(true);

        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            ID: ID,
            Start_Date: str,
            End_Date: end
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'accounts/ledger',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(api_config);
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status == true) {
                    // Handle success
                    setAccount(response.data.account);
                    SetLedger(response.data.ledger);
                    setOpeningBalance(response.data.OpeningBalance);

                    setLoading(false);

                } else {
                    // Handle error
                    setLoading(false);
                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });




    }, [str, end]);




    const columns = [
        {
            title: 'SR #',
            dataIndex: '',
            key: 'sr',
            width: 100,
            render: (text, record, index) => (
                record.id === 'opbalance' || record.id === 'closingbalance' ? '' : index
            ),
        },
        {
            title: 'Date',
            dataIndex: 'Dated',
            key: 'date',
            render: (_, record) => (
                record.id == "opbalance" || record.id == "closingbalance" ? "" : dayjs(record.Dated).format("DD-MM-YYYY")
            )
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'description',
            width: 500
        },
       
        {
            title: 'Debit',
            dataIndex: 'Debit',
            key: 'Debit',
            render: (_, record) => (
                record.id == "opbalance" || record.id == "closingbalance" ? ""
                    :
                    record.AmountType == "Debit" ? NumberFormat(record.Amount) : 0
            )

        },
        {
            title: 'Credit',
            dataIndex: 'Credit',
            key: 'Credit',
            render: (_, record) => (
                record.id == "opbalance" ?
                    <strong style={{ display: "block", textAlign: "right" }}>Opening Balance</strong>
                    :
                    record.id == "closingbalance" ?
                        <strong style={{ display: "block", textAlign: "right" }}>Closing Balance</strong>
                        :
                        record.AmountType == "Credit" ? NumberFormat(record.Amount) : 0
            )

        },

        {
            title: 'Balance',
            dataIndex: 'Balance',
            key: 'Balance',
            render: (_, record) => (
                record.id == "opbalance" ? <strong>{NumberFormat(record.Balance)}</strong> : NumberFormat(record.Balance)
            )

        },
    ];


    useEffect(() => {
        if (ledger) {
            if(ledger.length > 0) {
                const lastRow = ledger[ledger.length - 1];
                const closingBalanceRow = { id: 'closingbalance', Debit: 'Closing Balance', Balance: lastRow.Balance };
                const totalRow = { id: 'opbalance', Credit: 'Opening Balance', Balance: 0 };
                const newDataSourceWithTotal = [totalRow, ...ledger, closingBalanceRow];
                setdataSourceWithTotal(newDataSourceWithTotal);
            }
        }
    }, [loading]);

    const handleDateRange = (date, dateString) => {
        const link = "/accounts/ledger/?id=" + ID + "&str=" + dateString[0] + "&end=" + dateString[1];
        navigate(link);
    }


    return (
        <>
            <div class="page-content">
                <Breadcrumb
                    items={[
                        {
                            title: <NavLink to="/dashboard/analytics">Dashboard</NavLink>,
                        },
                        {
                            title: <NavLink to="/accounts">Accounts</NavLink>,
                        },
                        {
                            title: Account.AccountName,
                        },
                    ]}
                />
                <Card
                    title={`${Account.AccountName} - FROM ${dayjs(str).format("DD MMMM YYYY")} - ${dayjs(end).format("DD MMMM YYYY")}`}
                    extra={<RangePicker onChange={handleDateRange} />}
                >
                    <Table 
                        dataSource={dataSourceWithTotal} 
                        columns={columns} 
                        size="small" 
                        pagination={false} 
                        scroll={{y: 440,}}
                        loading={loading} />
                </Card>
            </div>
        </>
    );
}

export default AccountLedger;
import React, { useEffect, useState } from 'react';
import Header from '../Layout/Header';

import SecurityMenu from './SecurityMenu';
import config from '../../config';
import axios from 'axios';
import { Row, Col, Card, Form, Input, Radio, Select, message, Breadcrumb, Checkbox, Avatar, Button } from 'antd';
import { NavLink } from 'react-router-dom';
import logo from "../../assets/images/header-img.png"



function ChangeCompanyInfo() {


    const [Profile, setProfile] = useState({})
    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);



    useEffect(() => {
        setLoading(true);
        const data = {
            UserID: UserID,
            AccessKey: AccessKey
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/GetCompanyProfile',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(api_config);


        axios(api_config)
            .then(function (response) {
                console.log(response.data)
                if (response.data.status_code == 1) {
                    setProfile(response.data.data);
                    setLoading(false);
                } else {
                    // Handle error
                }
            })
            .catch(function (error) {
                console.log(error);
            });


    }, []);

    const handleSubmit = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setLoading(true);
            const data = {
                UserID: UserID,
                AccessKey: AccessKey,
                Name: event.target.txtNam.value,
                Mobile: event.target.value,
                Fax: event.target.txtFaxNo.value,
                Website: event.target.txtWebsite.value,
                Address: event.target.txtAddress.value,
                Phone: event.target.NumPhn.value,
                Mobile: event.target.numMbl.value,
                Show_Pumps: event.target.showCheck.value,
                Email: event.target.txtEml.value


            };
            console.log(data)

            var api_config = {
                method: 'post',
                url: config.base_url + 'Members/UpdateCompanyProfile',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            //console.log(api_config);

            axios(api_config)
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.status_code == 1) {

                        setLoading(false);

                        // Handle success
                    } else {
                        // Handle error
                        setLoading(false);

                    }

                })
                .catch(function (error) {

                    console.log(error);
                });
        }

        setValidated(true);
    };
    let checkboxStates = [false, false];

    const handleCheckboxChange = (index) => {
        // Flip the state of the checkbox at the specified index
        checkboxStates[index] = !checkboxStates[index];
    }

    const ImageCheckbox = ({ image, index }) => (
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
            <Checkbox onChange={() => handleCheckboxChange(index)} />
            <Avatar src={image} style={{ marginLeft: -28, marginTop: 95, height: 50, width: 50 }} />
        </div>
    );



    return (
        <>


            <div class="page-content">
                <Breadcrumb
                    items={[
                        {
                            title: <NavLink to="/dashboard/analytics">Dashboard</NavLink>,
                        },
                        {
                            title: 'Change Company',
                        },
                    ]}
                />
                <Row gutter={[24, 24]}>

                    <Col xs={24} md={8} offset={8}>

                        <Card title="Change Company Info">

                            {
                                // !loading &&
                                <Form
                                    name="basic"
                                    layout="vertical"



                                    initialValues={Profile}
                                    // onFinish={handleSubmit}
                                    //    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                >
                                    <Form.Item
                                        label="Company Name"
                                        name="Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter Pump name',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>


                                    <Form.Item
                                        label="Mobile"
                                        name="Mobile"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter Fax',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>



                                    <Form.Item
                                        label="Website"
                                        name="Website"
                                        
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="City"
                                        name="City"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter City name',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Address"
                                        name="Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter Address',
                                            },
                                        ]}
                                    >
                                        <Input  />
                                    </Form.Item>
                                    <Button block type='primary'>Save</Button>


                                </Form>

                            }




                        </Card>
                    </Col>


                </Row>



            </div>

        </>
    );
}

export default ChangeCompanyInfo;

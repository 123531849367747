import React, {useState, useEffect} from 'react'
import logo from "../../assets/images/header-img.png"
import moment from 'moment/moment';
import { Col, Row, Space, Button, theme } from 'antd';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
} from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import config from '../../config';
import axios from 'axios';



function Header(props) {


    let user1 = localStorage.getItem("User");
    const [user, setUser] = useState(JSON.parse(user1));
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(user.ID);

    const today = dayjs().format("YYYY-MM-DD");

    const [LastPage, setLastPage] = useState(today);
    const [loading, setLoading] = useState(false);
    const Company = user.Name;
  

    useEffect(() => {
        setLoading(true);


        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'daybook/get_last_page',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(api_config);
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status == true) {
                    // Handle success
                    setLastPage(response.data.last_page);
                    setLoading(false);

                } else {
                    // Handle error
                    setLoading(false);
                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });




    }, []);


    const handleSignOut = () => {
        localStorage.clear();
        window.location.href = "/login";
    }






    return (
        <>
            <header>
                <Row>
                    <Col span={8}>
                        <div className='page-title'>
                            <h3 className='m-0'>Daybook</h3>
                            <p style={{margin: "0 0 0 12px"}}>{Company}</p>
                        </div>
                    </Col>
                    <Col span={16}>
                        <ul class="header-menu">
                            <li>
                                <NavLink to="/dashboard">
                                    <i className="material-symbols-outlined">dashboard</i>
                                    <span>Dashboard</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/accounts/">
                                    <i className="material-symbols-outlined">
                                        group
                                    </i>
                                    <span>Accounts</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/daybook?date=${LastPage}`}>
                                    <i className="material-symbols-outlined">
                                        date_range
                                    </i>
                                    <span>Daybook</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/daybook?date=${LastPage}`}>
                                    <i className="material-symbols-outlined">
                                        search
                                    </i>
                                    <span>Search</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink to="/profile">
                                    <i className="material-symbols-outlined">person</i>
                                    <span>Profile</span>
                                </NavLink>
                            </li>

                            <li>
                                <a onClick={handleSignOut}>
                                    <i className="material-symbols-outlined">
                                        logout
                                    </i>
                                    <span>Sign Out</span>
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </header>
        </>
    )
}

export default Header

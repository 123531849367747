import React, { useEffect, useState } from "react"
import axios from 'axios'
import config from '../../config'
import {  Spin } from 'antd';
import { NumberFormat, calculateColumnTotal } from "../Shared/Functions";
import dayjs from "dayjs";
import ReportHeader from "../Shared/ReportHeader";

function PrintAccounts() {
    let user1 = localStorage.getItem("User");
    const [user, setUser] = useState(JSON.parse(user1));
    const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [UserID, setUserID] = useState(user.ID);

    const [listofAccounts, SetlistofAccounts] = useState([])
    const [loading, setLoading] = useState(false);



    const [company, setCompany] = useState({});




    useEffect(() => {
        document.title = "Manage Accounts - Daybook";
    }, []);


    useEffect(() => {
        setLoading(true);
        const data = {
            UserID: UserID,
            AccessKey: AccessKey
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'accounts/get',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        console.log(api_config);
        axios(api_config)
            .then(function (response) {
                //console.log(response.data);
                if (response.data.status == true) {
                    // Handle success
                    SetlistofAccounts(response.data.ListOfAccounts);
                    setLoading(false);

                } else {
                    // Handle error
                    setLoading(false);
                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });




    }, []);


    useEffect(() => {

        const data = {
            UserID: UserID,
            AccessKey: AccessKey
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'accounts/get_company',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(api_config);
        axios(api_config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.status == true) {
                    // Handle success
                    setCompany(response.data.company);

                } else {
                    // Handle error
                    setLoading(false);
                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });




    }, []);





    const renderAccounts = () => {
        if (listofAccounts.length > 0) {
            return (
                listofAccounts.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.AccountName}</td>
                            <td>{NumberFormat(item.Balance)}</td>
                        </tr>
                    )
                })
            )
        }
    }








    return (
        <>
            <div className="page">
                <div className="subpage">
                    {
                        loading ?
                            <Spin />
                            :
                            <>
                                <ReportHeader />

                                <h2 style={{ fontSize: "15px" }}>Accounts {dayjs().format("D, MMM YYYY")}</h2>

                                <table className="report-table accounts-print-table">
                                    <thead>
                                        <tr>
                                            <th>SR#</th>
                                            <th>Name</th>
                                            <th>Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderAccounts()}

                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td></td>
                                            <td style={{ textAlign: "right" }}><strong>Total:</strong></td>
                                            <td><strong>{NumberFormat(calculateColumnTotal(listofAccounts, 'Balance'))}</strong></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </>
                    }


                </div>
            </div>
        </>
    )
}

export default PrintAccounts

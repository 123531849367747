import React, { useState } from 'react';
import axios from "axios";
import config from '../../config';

import { NavLink, useNavigate } from 'react-router-dom';
import { Button, message, Form, Input } from 'antd';
function SignUp() {
  const [validated, setValidated] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [PumpName, setPumpName] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");





  let navigate = useNavigate();

  const handleSubmit = (values) => {

    // Extract form values from the 'values' object
    const { PumpName, Mobile, Password, Email } = values;

    // Set the state variables with the extracted values
    setPumpName(PumpName);
    setMobile(Mobile);
    setPassword(Password);
    setEmail(Email);

    setLoading(true);

    const data = {
      Name: PumpName,
      Mobile: Mobile,
      Email: Email,
      Password: Password,
    };

    var api_config = {
      method: 'post',
      url: config.base_url + 'Members/SignUp',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          navigate('/login');
          // Handle success
        } else {
          setLoading(false);
          messageApi.open({
            type: 'error',
            content: response.data.status_message,
          });

        }
        setLoading(false);
      })
      .catch(function (error) {

        console.log(error);
      });


    setValidated(true);
  };

  return (
    <>
      {contextHolder}
      <div className="auth-wrap">
        <div>
          {/* <form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="auth-card">
              <h2> Sign Up </h2>
              {hasError === true && (
                <div className="alert alert-danger">{errorMsg}</div>
              )}
              <div className="form-floating mb-3">
                <input
                  name="txtPumpName"
                  type="text"
                  id="txtPumpName"
                  className="form-control"
                  placeholder="Business Name"
                  required=""
                />
                <label htmlFor="txtPumpName">Business Name</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  name="txtMobileNo"
                  type="text"
                  id="txtMobileNo"
                  className="form-control"
                  placeholder="Mobile No"
                  required=""
                />
                <label htmlFor="txtMobileNo">Mobile No</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  name="txtEmal"
                  type="text"
                  id="txtEmal"
                  className="form-control"
                  placeholder="Email Address"
                  required=""
                />
                <label htmlFor="txtEmal">Email Address</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  name="txtPassword"
                  type="password"
                  id="txtPassword"
                  className="form-control"
                  placeholder="Password"
                  required=""
                />
                <label htmlFor="txtPassword">Password</label>
              </div>
              <div className="form-floating mb-3"></div>
              <input
                type="submit"
                name="btnSubmit"
                value="Create my account"
                id="btnSubmit"
                className="button button-primary w-100"
              />
              <div className="text-center mt-3">
                <NavLink to="/login">Sign In</NavLink>
              </div>
            </div>
          </form> */}
          <div class="auth-wrap">
            <div class="auth-head">
              <p>Welcome to</p>
              <h1>Petrol Pump Manager</h1>
            </div>
            <div class="auth-card">
              <Form
                name="normal_Signup"
                className="Signup-form"
                size="large"
                layout="vertical"
                onFinish={handleSubmit}
              >
                <Form.Item
                  name="PumpName"
                  label="Business Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your Business Name!',
                    },
                  ]}
                >
                  <Input prefix={<span className="material-symbols-outlined site-form-item-icon">account_balance</span>} placeholder="Business Name" />
                </Form.Item>

                <Form.Item
                  name="Mobile"
                  label="Mobile No"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Mobile No',
                    },
                  ]}
                >
                  <Input prefix={<span className="material-symbols-outlined site-form-item-icon">call</span>} placeholder="Username" />
                </Form.Item>
                <Form.Item
                  name="Email"
                  label="Email Address"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your Email Address!',
                    },
                  ]}
                >
                  <Input prefix={<span className="material-symbols-outlined site-form-item-icon">email</span>}
                    type="email"
                    placeholder="Email Address" />
                </Form.Item>
                <Form.Item
                  name="Password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your Password!',
                    },
                  ]}
                >
                  <Input
                    prefix={<span className="material-symbols-outlined site-form-item-icon">lock</span>}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>

                <Form.Item>
                  <Button style={{ width: "100%" }} type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                    Sign Up
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;

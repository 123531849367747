const isLocalhost = window.location.hostname === 'localhost';
const isLive = window.location.hostname === 'app.daybook.pk';
const isHms = window.location.hostname === 'hms.sense-8.com';


let base_url;


if(isLocalhost) {
 //base_url = 'http://localhost/projects/daybook.pk/apidaybook/index.php/';
 base_url = 'http://apifarmeci.sense-8.com/apidaybook/index.php/';
 
  
}
else if(isLive) {
  base_url = 'http://apifarmeci.sense-8.com/apidaybook/index.php/';
 
}

const config = {
  base_url
};

//console.log(window.location.hostname);
//console.log(config);

// const config = {
//   base_url: isLocalhost ? 'http://localhost/api/index.php/' : 'http://apifarmeci.sense-8.com/apihms/index.php/',
//   uploads: isLocalhost ? 'http://localhost/api/uploads/' : 'http://apifarmeci.sense-8.com/apihms/uploads/',
// };


// const config = {
//   base_url: isLocalhost ? 'http://localhost/api/index.php/' : 'http://apifarmeci.sense-8.com/apipmslive/index.php/',
//   uploads: isLocalhost ? 'http://localhost/api/uploads/' : 'http://apifarmeci.sense-8.com/apipmslive/uploads/',
// };



export default config;
